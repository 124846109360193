import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { message, Button, Col, Row, Modal } from "antd";
import { GetQizeById, SubmitTest, UserTestAttempts } from "../../apis/Api";
import { useNavigate, useParams } from "react-router";
import Loader from "../Loader/Loader";
import "./Quiz.scss";
import TestResult from "./TestResult/TestResult";
import { BsXLg } from "react-icons/bs";

const Quiz = ({ courseid, SelectedLecturetype }) => {
  const [answers, setAnswers] = useState({});
  const [quizData, setQuizData] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [attempts, setAttempts] = useState(0);
  const [maxAttemptsReached, setMaxAttemptsReached] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingAttempts, setLoadingAttempts] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [timeUpPopup, setTimeUpPopup] = useState(false); // State for time-up popup
  const { id } = useParams();
  const navigate = useNavigate();

  // Handle browser before unload event
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!resultData) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        e.returnValue = message;
        return message;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [resultData]);

  // Handle browser pop state (back button)
  useEffect(() => {
    const handlePopState = (e) => {
      if (!resultData) {
        e.preventDefault();
        setIsModalVisible(true);
      }
    };
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [resultData]);

  // Fetch quiz data
  useEffect(() => {
    const fetchQuizData = async () => {
      setLoading(true);
      try {
        const res = await GetQizeById(courseid);
        if (res.data.success) {
          setQuizData(res.data.data);
          sessionStorage.removeItem(`timeRemaining_${courseid}`);
          const durationInSeconds = parseInt(res.data.data.duration) * 60;
          const storedTime = sessionStorage.getItem(
            `timeRemaining_${courseid}`
          );
          setTimeRemaining(
            storedTime ? parseInt(storedTime) : durationInSeconds
          );

          const initialAnswers = res.data.data.test_questions.reduce(
            (acc, question) => {
              acc[question.id] = null;
              return acc;
            },
            {}
          );
          setAnswers(initialAnswers);
        }
      } catch (error) {
        console.log("Error fetching quiz data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuizData();
  }, [courseid]);

  // Fetch user attempts
  useEffect(() => {
    const fetchUserAttempts = async () => {
      setLoadingAttempts(true);
      try {
        const response = await UserTestAttempts(courseid);
        const userAttempts = response.data.data.message;

        // if (userAttempts === "No attempts left.") {
        //   message.error("You have no attempts left to take this quiz.");
        //   // navigate("/dashboard"); // Navigate the user away from the quiz page if no attempts are left
        //   return; // Exit the function to prevent further processing
        // }

        setAttempts(userAttempts);

        // if (userAttempts == 0) {
        //   navigate("/dashboard");
        // }
      } catch (error) {
        console.log("Error fetching attempts:", error);
      } finally {
        setLoadingAttempts(false);
      }
    };

    fetchUserAttempts();
  }, [courseid, navigate]);

  // Timer countdown effect
  useEffect(() => {
    if (timeRemaining === null) return;

    if (timeRemaining === 0) {
      setIsTimeUp(true);
      handleSubmit(true); // Auto-submit when time is up
      setTimeUpPopup(true); // Show time-up notification
      return;
    }

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        const newTime = prevTime - 1;
        sessionStorage.setItem(`timeRemaining_${courseid}`, newTime);
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeRemaining, courseid]);

  // Handle answer change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnswers({ ...answers, [name]: parseInt(value) });
  };

  // Handle form submit
  const handleSubmit = (isAutomatic = false) => {
    const unansweredQuestions = Object.values(answers).some(
      (answer) => answer === null
    );

    if (!isAutomatic && unansweredQuestions) {
      message.warning(
        "Please answer all questions before submitting the quiz."
      );
      return; // Exit if there are unanswered questions
    }

    // If all questions are answered, show the modal to confirm submission
    if (!isAutomatic) {
      setIsModalVisible(true);
    }
  };

  // Handle confirmation
  const handleOk = () => {
    const formData = {
      test_id: quizData.id,
      answers: Object.keys(answers).map((question_id) => ({
        question_id: parseInt(question_id),
        user_answer_id: answers[question_id],
      })),
    };

    SubmitTest(formData)
      .then((res) => {
        if (res.data.success) {
          setResultData(res.data.data);
          // SelectedLecturetype("")
          sessionStorage.removeItem(`timeRemaining_${id}`);
        }
      })
      .catch((error) => console.error("Error submitting quiz:", error))
      .finally(() => setIsModalVisible(false)); // Close the modal after submission
  };

  // Handle cancel submission
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Format timer (minutes:seconds)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // Handle navigation back
  const handleBack = () => {
    if (!resultData) {
      setIsModalVisible(true);
      return;
    }
    navigate("/dashboard");
  };

  if (loading || loadingAttempts) {
    return (
      <div className="quizLoading">
        <Loader />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="quiz-container">
      <Container>
        {maxAttemptsReached ? (
          <div className="attempt-limit-message">
            <h2>You have reached the maximum number of attempts (3).</h2>
            <p>Unfortunately, you cannot attempt this quiz anymore.</p>
          </div>
        ) : !resultData ? (
          <>
            <div className="header">
              {/* <div className="d-flex">
                <BsXLg style={{ cursor: "pointer" }} size={22} onClick={handleBack} />
                <h3 className="title">{quizData.name}</h3>
              </div> */}
              
              <div className="Attempts">{attempts} {attempts==="No attempts left."?"":"Attempts Remaning"}</div>
              <div className="timer">Timer: {formatTime(timeRemaining)}</div>
            </div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className="test-form"
            >
              {quizData.test_questions?.map((question) => (
                <Form.Group as={Row} className="mb-3" key={question.id}>
                  <Form.Label as="legend" column sm={12}>
                    {question.question}
                  </Form.Label>
                  {question.test_options?.map((option) => (
                    <Col sm={10} lg={10} key={option.id}>
                      <div className="custom-radio">
                        <input
                          type="radio"
                          id={`q${question.id}-${option.id}`}
                          name={question.id.toString()}
                          value={option.id}
                          onChange={handleChange}
                        />
                        <label htmlFor={`q${question.id}-${option.id}`}>
                          {option.option}
                        </label>
                      </div>
                    </Col>
                  ))}
                </Form.Group>
              ))}
              <Button htmlType="submit"disabled={attempts==="No attempts left."}>Submit</Button>
            </Form>

            {/* Modal for confirmation is removed when time is up */}
            <Modal
              title="Confirm Submission"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              okText="Submit"
              cancelText="Cancel"
            >
              <p>Are you sure you want to submit your answers?</p>
            </Modal>

            {/* Time up notification */}
            <Modal
              title="Time is Up"
              visible={timeUpPopup}
              onOk={() => setTimeUpPopup(false)}
              okText="OK"
              cancelButtonProps={{ style: { display: "none" } }}
            >
              <p>
                Your time has expired, and your quiz has been automatically
                submitted.
              </p>
            </Modal>
          </>
        ) : (
          <TestResult
            quizData={quizData}
            resultData={resultData}
            SelectedLecturetype={SelectedLecturetype}
          />
        )}
      </Container>
    </div>
  );
};

export default Quiz;
