import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import DashboardManagement from "./DashboardManagement";
import UserManagement from "./UserManagement";
import CorporateManagement from "./CorporateManagement";
import CourseManagement from "./CourseManagement";
import TestManagement from "./TestManagement";
import PaymentManagement from "./PaymentManagement";
import ContentManagement from "./ContentManagement";
import AdminManagement from "./AdminManagement";
import "./Guidelines.scss";

const Guidelines = () => {
  const [selectedItem, setSelectedItem] = useState("Dashboard");
  const sectionsRef = useRef({});

  const sections = [
    "Dashboard",
    "User Management",
    "Corporate Management",
    "Course Management",
    "Test Management",
    "Payment Management",
    "Content Management",
    "Access Management",
  ];

  // Handle click on a section item
  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (sectionsRef?.current[item]) {
      sectionsRef?.current[item].scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // IntersectionObserver to detect which section is in view
    const observer = new IntersectionObserver(
      (entries) => {
        entries?.forEach((entry) => {
          if (entry?.isIntersecting) {
            setSelectedItem(entry.target.dataset.section);
          }
        });
      },
      // { threshold: 0.5 } // Adjust threshold for better visibility detection
    );

    Object.values(sectionsRef.current).forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    // Cleanup the observer when component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="Guidelines">
      <Container>
        <h1>LMS Guide</h1>
        <div className="guidelines-container">
          <div className="guidelines-left">
            <ul>
              {sections.map((item) => (
                <li
                  key={item}
                  onClick={() => handleItemClick(item)}
                  className={selectedItem === item ? "active" : ""}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="guidelines-right">
            {sections.map((section) => (
              <div
                key={section}
                data-section={section}
                ref={(el) => {
                  if (el) {
                    sectionsRef.current[section] = el;
                  }
                }}
                className="guideline-section mt-0"
              >
                {section === "Dashboard" && <DashboardManagement order="1" />}
                {section === "User Management" && <UserManagement order="2" />}
                {section === "Corporate Management" && (
                  <CorporateManagement order="3" />
                )}
                {section === "Course Management" && (
                  <CourseManagement order="4" />
                )}
                {section === "Test Management" && <TestManagement order="5" />}
                {section === "Payment Management" && (
                  <PaymentManagement order="6" />
                )}
                {section === "Content Management" && (
                  <ContentManagement order="7" />
                )}
                {section === "Access Management" && (
                  <AdminManagement order="8" />
                )}
                {/* <hr /> */}
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Guidelines;
