import React, { useRef } from "react";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import "./Certificate.scss";

const Certificate = ({ data }) => {
  const certificateRef = useRef();

  // Function to download the certificate as an image
  const downloadAsImage = async () => {
    const node = certificateRef.current;
    if (node) {
      try {
        const dataUrl = await toPng(node, {
          cacheBust: true,
          pixelRatio: 3, // High resolution
        });
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "certificate.png";
        link.click();
      } catch (error) {
        console.error("Error generating image:", error);
      }
    }
  };

  // Function to download the certificate as a PDF
  const downloadAsPDF = async () => {
    const node = certificateRef.current;
    if (node) {
      const dataUrl = await toPng(node, {
        cacheBust: true,
        pixelRatio: 3, // Match the pixel ratio for consistency
      });
      const pdf = new jsPDF("landscape");
      pdf.addImage(dataUrl, "PNG", 10, 10, 280, 190); // Adjust dimensions as needed
      pdf.save("certificate.pdf");
    }
  };

  const downloadCertificate = async () => {
    const node = certificateRef.current;
    if (node) {
      const dataUrl = await toPng(node, {
        pixelRatio: 4, // High pixel ratio for better clarity
        cacheBust: true,
      });
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "certificate.png";
      link.click();
    }
  };
  

  return (
    <div className="Certificate">
      <div className="certificate-container">
        <div className="certificate" ref={certificateRef}>
          <div className="water-mark-overlay"></div>
          <div className="certificate-body">
            <h1>Certificate of Completion</h1>
            <h6>This Certificate is Awarded to </h6>
            <p className="student-name">
              {data?.user_dashboard?.user_profile?.name}
            </p>
            <p className="topic-title">
              For Successfully completing the{" "}
              <b>{data?.dashboard_course?.name}</b> on{" "}
              <i>{new Date(data.created_at).toLocaleDateString()}</i>
            </p>
          </div>
        </div>
        <div className="buttons">
          <button onClick={downloadAsImage}>Download as Image</button>
          <button onClick={downloadAsPDF}>Download as PDF</button>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
