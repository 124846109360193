import React from "react";
import { Table } from "react-bootstrap";
const UserTableManagement = () => (
  <div>
    <p>
      The User Management module provides a comprehensive way to handle user
      data, view specific user details, and perform various actions such as
      exporting, deleting, and managing users.
    </p>
    <p>
      Upon accessing the <strong> User Management </strong>  section, you will see a table listing
      all registered users. The table displays columns such as <strong>  S.No. (serial
      number), Name, Email, Mobile, Data Shared, No. of Courses Purchased,
      Account Creation Date, </strong> and an <strong> Action </strong>  column.
    </p>
  </div>
);
const ActionsOnIndividualUsers = () => (
  <div>
    <ol>
      In the <strong> Action </strong>  column, there are two icons:
      <li style={{ listStyleType: "number" }}>
       <strong> View Icon (Eye symbol) </strong> : Clicking on the <strong>  view icon  </strong>opens a detailed view
        of the selected user&#39;s information. This includes their name, email,
        phone number, and a breakdown of associated <strong> Courses </strong>  and <strong> Tests </strong> . For each
        course or test, you can view details like price, progress, duration,
        number of questions, certificates, and purchase date.
      </li>
      <li style={{ listStyleType: "number" }}>
       <strong> Delete Icon (Trash symbol) </strong> : Clicking the <strong> delete icon </strong>  will remove the
        user from the system after confirmation.
      </li>
    </ol>
  </div>
);
const ExportingUserData = () => (
  <div>
    <p>
      The green <strong> Export </strong>  button at the top-right corner of the user table allows
      you to <strong> export all user data in CSV format </strong> . This provides an easy way to
      generate reports or analyze data offline.
    </p>
  </div>
);
const BulkActions = () => (
  <div>
    <ul>
      You can perform bulk actions by selecting multiple users using the
      <strong> checkboxes on the left side </strong>  of the table. For example:
      <li style={{ listStyleType: "disc" }}>
        Select the checkboxes for multiple users and click on the <strong> delete icon </strong>  to
        remove all selected users at once. This saves time when managing large
        lists of users.
      </li>
    </ul>
    <p>
      <strong> Example: </strong>
    </p>
    <ul>
      Suppose you need to delete three users at once. You can select them using
      the checkboxes next to their names and confirm deletion.
      <li style={{ listStyleType: "disc" }}>
      <strong> Name 1</strong>  : John Doe (Email: abc1@domain.com, Mobile: 123456789)
      </li>
      <li style={{ listStyleType: "disc" }}>
      <strong> Name 2</strong> : Jane Smith (Email: abc2@domain.com, Mobile: 123456XXX)
      </li>
      <li style={{ listStyleType: "disc" }}>
      <strong> Name 3</strong> : Mark Lee (Email: abc3@domain.com, Mobile: 123456YYY)
      </li>
    </ul>
    <p>After confirmation, all three users will be removed simultaneously.</p>
  </div>
);
const UserDetailsOverview = () => (
  <div>
    <ul>
      When you click the <strong> view icon </strong> for a specific user, a detailed breakdown of
      their activities is displayed. This includes:
      <li style={{ listStyleType: "disc" }}>
        <ul>
          <strong> Course Details: </strong>
          {/* list-style-type: circle */}
          <li style={{ listStyleType: "circle" }}>
            Course Name, Course Price, Progress (percentage of completion),
            Certificate (if issued), and Purchase Date.
          </li>
        </ul>
      </li>
      <li style={{ listStyleType: "disc" }}>
        <ul>
          <strong> Test Details: </strong>
          <li style={{ listStyleType: "circle" }}>
            Test Name, Test Duration, Number of Questions, Purchase Date, and
            Test Attempt Report.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      For users who have taken tests, you can click on <strong> &quot;View Report&quot; </strong> 
      under the <strong> Action </strong>  column to see detailed marks and attempt history. This
      opens a modal listing the test attempts, marks scored (e.g., 3/5), and the
      test attempt date.
    </p>
    <h6>Example of Test Marks Data:</h6>
    <Table size="sm">
      <thead>
        <tr>
          <th>Attempt Number</th>
          <th>Marks</th>
          <th>Test Attempt Date</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>3/5</td>
          <td>2024-11-07 16:35:45</td>
        </tr>
        <tr>
          <td>2</td>
          <td>3/5</td>
          <td>2024-11-07 16:35:45</td>
        </tr>
        <tr>
          <td>3</td>
          <td>4/5</td>
          <td>2024-11-07 16:35:45</td>
        </tr>
      </tbody>
    </Table>
  </div>
);
const UserManagement = ({ order }) => {
  const sections = [
    {
      title: "User Table Management",
      description: <UserTableManagement />,
      subSections: [
        {
          title: "Actions on Individual Users",
          content: <ActionsOnIndividualUsers />,
        },
        {
          title: "Exporting User Data",
          content: <ExportingUserData />,
        },
        {
          title: "Bulk Actions",
          content: <BulkActions />,
        },
        {
          title: "User Details Overview",
          content: <UserDetailsOverview />,
        },
      ],
    },
  ];
  return (
    <ol style={{ listStyle: "none" }} className="font_size_17">
      {sections.map((section, sectionIndex) => (
        <li key={`section-${sectionIndex}`}>
          <h2>
            {Number(sectionIndex) + Number(order)}. {section.title}
          </h2>
          <div>{section.description}</div>
          {section.subSections && (
            <ol style={{ listStyle: "none" }} className="font_size_17">
              {section.subSections.map((subSection, subIndex) => (
                <li key={`subsection-${subIndex}`}>
                  <div className="heading">
                    {Number(sectionIndex) + Number(order)}.{subIndex + 1}{" "}
                    {subSection.title}
                  </div>
                  <div>{subSection.content}</div>
                </li>
              ))}
            </ol>
          )}
        </li>
      ))}
    </ol>
  );
};
export default UserManagement;
