import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/Logo.png";
import "./ShowCertificate.scss";
import { Container } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
const ShowCertificate = () => {
  const location = useLocation();
  const { certificateData } = location.state || {};

  const contentRef = useRef();

  return (
    <div className="certificate">
      <div ref={contentRef}>
        <Container>
          <div className="certificateDetails">
            <div className="content">
              <div className="main-content">
                <h1>Certificate</h1>
                <h2>of Completion</h2>
                <p className="text">This certificate is presented to</p>
                <p className="participant">
                  {certificateData?.user_dashboard.user_profile.name}
                </p>
                <p className="text">
                  for successful completion of the{" "}
                  <span className="course">
                    {certificateData?.dashboard_course?.name}
                  </span>
                </p>
                <img className="logo" src={logo} alt="Logo" />
              </div>
              <div className="signatures">
                <div className="director">
                  <p className="name">
                    <strong>Jamille Thomas</strong>
                  </p>
                  <p className="designation">executive director</p>
                </div>
                <div className="president">
                  <p className="name">
                    <strong>Candace Woodruff</strong>
                  </p>
                  <p className="designation">president</p>
                </div>
              </div>
              <span className="yblock yvertical yellow-v1"></span>
              <span className="yblock yvertical yellow-v2"></span>
              <span className="yblock yhorizontal yellow-h1"></span>
              <span className="yblock yhorizontal yellow-h2"></span>
            </div>
            <span className="bblock bvertical black-v1"></span>
            <span className="bblock bvertical black-v2"></span>
            <span className="bblock bhorizontal black-h1"></span>
            <span className="bblock bhorizontal black-h2"></span>
          </div>
        </Container>
      </div>
      {/* <button className="button_theme" onClick={handlePrint}>Generate PDF</button> */}
    </div>
  );
};

export default ShowCertificate;
