import React from "react";
const PaymentManagementTop = () => {
  <div>
    <p>
      The <strong> Payment Management </strong> section on the platform allows
      admins to monitor and manage the payment history of all users. The section
      provides a clear overview of transactions, including critical details such
      as the user’s name, transaction amount, payment mode, and transaction
      date. This consolidated information is presented in a tabular format for
      easy tracking and analysis.
    </p>
  </div>;
};
const PaymentInformation = () => (
  <div>
    <ul>
      Each entry in the table consists of:
      <li style={{ listStyleType: "disc" }}>
        <strong> User's Name: </strong>
        Identifies the user who made the payment.
      </li>
      <li style={{ listStyleType: "disc" }}>
        <strong> Amount: </strong>
        Displays the total transaction value.
      </li>
      <li style={{ listStyleType: "disc" }}>
        <strong> Payment Mode: </strong>
        Indicates the method used for the transaction, such as <em>CARD</em>.
      </li>
      <li style={{ listStyleType: "disc" }}>
        <strong> Transaction Date: </strong>
        Specifies the exact date and time of the payment.
      </li>
      <li style={{ listStyleType: "disc" }}>
        <strong> Action: </strong>
        Allows the admin to view the detailed payment receipt.
      </li>
    </ul>
    <p>
      The <strong> Action </strong>  column includes a contextual menu with an option to{" "}
      <strong> View Receipt </strong> . This receipt is generated through{" "}
      <strong> SquareUp </strong> , the payment portal integrated into the
      platform. By clicking on this option, admins can view the complete
      breakdown of the payment transaction, including details that may not be
      immediately visible in the table.
    </p>
    <p>
      Additionally, the platform provides a search functionality to help admins
      quickly find specific transactions based on keywords or user information.
      Admins can also export the payment data as a CSV file using the{" "}
      <strong>green export button</strong> located on the top-right corner of
      the screen.
    </p>
    <h6>Example:</h6>
    <p>
      Suppose a user named abc@domain.com made a payment of{" "}
      <strong> $200 </strong> via card on
      <strong> 11-26-2024 </strong> . The admin can view this record in the
      table and click on the <strong>View Receipt</strong>  option to access a detailed breakdown
      of the transaction, including taxes, fees, and confirmation ID.
    </p>
    <p>
      This section simplifies tracking payments, managing receipts, and ensuring
      seamless reconciliation of all financial activities.
    </p>
  </div>
);

const PaymentManagement = ({ order }) => {
  const sections = [
    {
      title: "Payment Management",
      description:<PaymentManagementTop/>,
      subSections: [
        {
          title: "Payment Information",
          content: <PaymentInformation />,
        },
      
      ],
    },
  ];
  return (
    <ol style={{ listStyle: "none" }} className="font_size_17">
      {sections.map((section, sectionIndex) => (
        <li key={`section-${sectionIndex}`}>
          <h2>
            {Number(sectionIndex) + Number(order)}. {section.title}
          </h2>
          <div>{section.description}</div>
          {section.subSections && (
            <ol style={{ listStyle: "none" }} className="font_size_17">
              {section.subSections.map((subSection, subIndex) => (
                <li key={`subsection-${subIndex}`}>
                  <div className="heading">
                    {Number(sectionIndex) + Number(order)}.{subIndex + 1}{" "}
                    {subSection.title}
                  </div>
                  <div>{subSection.content}</div>
                </li>
              ))}
            </ol>
          )}
        </li>
      ))}
    </ol>
  );
};
export default PaymentManagement;
