import React, { useEffect, useMemo, useState } from "react";
import { Progress, List, Drawer, Button, Col, Row, Spin } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "./CourseOngoing.scss";
import { Container, Form } from "react-bootstrap";
import CustomButton from "../CustomButton/CustomButton";
import {
  GetCertificates,
  GetCertificatesAPi,
  GetFindLecturesById,
  GetLecturesList,
  GetProgress,
  GetQuestionsByLectureId,
  LectureMarkAsComplete,
  PostAnswersByLectureId,
} from "../../apis/Api";
import ContentComponent from "./ContentComponent";
import { useNavigate, useParams } from "react-router";
import Result from "./Result";
import { FaCheckCircle } from "react-icons/fa";
import { FcCheckmark } from "react-icons/fc";
import Quiz from "../Quiz/Quiz";
import Certificate from "../CertificateList/Certificate";
const CourseOngoing = () => {
  const [lecturesList, setLecturesList] = useState([]);
  const [course, setCourse] = useState();
  const [certificate, setCertificate] = useState();
  const [courseTitle, setCourseTitle] = useState("");
  const [selectedLecture, setSelectedLecture] = useState(null);
  const [lectureContent, setLectureContent] = useState("");
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false); // State for loading spinner
  const [error, setError] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    showlecture();
    progressBar(id);
    CertificateTest();
  }, [id]);
  const showlecture = () => {
    GetLecturesList(id)
      .then((res) => {
        if (res?.data?.data?.is_purchased === false) {
          navigate("/dashboard");
        }
        setCourse(res?.data?.data?.course_test);
        setCourseTitle(res?.data?.data?.name || "");
        setLecturesList(res?.data?.data?.course_lectures || []);
        if (res?.data?.data?.course_lectures.length > 0) {
          setSelectedLecture(res?.data?.data?.course_lectures[0]);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const CertificateTest = () => {
    GetCertificatesAPi(id)
      .then((res) => {
        // console.log(res, "res");
        setCertificate(res?.data?.data);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  // console.log(certificate, "===certificate");
  const progressBar = () => {
    GetProgress(id)
      .then((res) => {
        const courseProgress = res.data.data.progress || 0;
        setProgress(courseProgress);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (selectedLecture) {
      GetFindLecturesById(selectedLecture.id)
        .then((res) => {
          setLectureContent(res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
      GetQuestionsByLectureId(selectedLecture.id)
        .then((res) => {
          setQuestions(res.data.data.lecture_quiz);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [selectedLecture]);
  const showDrawer = () => {
    setDrawerVisible(true);
  };
  const onClose = () => {
    setDrawerVisible(false);
  };
  const handleLectureClick = (lecture) => {
    setSelectedLecture(lecture);
    setDrawerVisible(false);
  };
  const [quizResults, setQuizResults] = useState([]);
  const [showScoreboard, setShowScoreboard] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async () => {
    // Check if all questions are answered
    const allAnswered = questions.every(
      (_, index) => answers[`q${index + 1}`] !== undefined
    );
    if (!allAnswered) {
      setError(true); // Display error message
      return;
    }
    setError(false); // Clear any previous error
    setLoading(true); // Start spinner
    const formattedAnswers = Object.keys(answers).map((key) => {
      const questionIndex = parseInt(key.replace("q", ""), 10) - 1;
      return {
        question_id: questions[questionIndex].id,
        user_answer_id: parseInt(answers[key], 10),
      };
    });
    const formData = {
      course_id: +id,
      lecture_id: selectedLecture.id,
      answers: formattedAnswers,
    };
    try {
      const res = await PostAnswersByLectureId(formData);
      setLoading(false); // Stop spinner
      CertificateTest();
      showlecture();
      setQuizResults([res.data.data]);
      setShowScoreboard(true);
    } catch (error) {
      setLoading(false); // Stop spinner on error
      console.error("Error submitting quiz:", error);
    }
  };
  const currentLevel =
    lecturesList.findIndex((lecture) => lecture.id === selectedLecture?.id) + 1;
  const totalLevels = lecturesList.length;
  const handleContinue = () => {
    const formData = {
      course_id: id,
      lecture_id: selectedLecture.id,
    };
    LectureMarkAsComplete(formData)
      .then((res) => {
        if (res.data && res.data.success) {
          progressBar(id);
          setShowScoreboard(false);
        }
        const currentIndex = lecturesList.findIndex(
          (lecture) => lecture.id === selectedLecture.id
        );
        const updatedLecturesList = lecturesList?.map((lecture, index) =>
          index === currentIndex ? { ...lecture, is_completed: true } : lecture
        );
        setLecturesList(updatedLecturesList);
        if (currentIndex < lecturesList.length - 1) {
          setSelectedLecture(lecturesList[currentIndex + 1]);
          setSelectedAnswer({});
          setAnswers({});
        } 
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };
  const restartQuiz = () => {
    setShowScoreboard(false); // Close scoreboard
    setQuizResults(null); // Reset quiz results
    // Add additional logic for restarting the quiz
  };
  
  const handleChange = (e, questionIndex, answerId) => {
    setAnswers((prev) => ({
      ...prev,
      [`q${questionIndex + 1}`]: answerId, // Update answer for the current question
    }));
  };
  const handleOpenTest = () => {
    setSelectedLecture("test");
  };
  const handleOpenCertificate = () => {
    setSelectedLecture("certificate");
  };
  return (
    <Container>
      <div className="course-ongoing">
        <Button
          className="menu-button"
          icon={<MenuOutlined />}
          onClick={showDrawer}
        />
        <Drawer
          title={courseTitle}
          placement="left"
          closable={true}
          onClose={onClose}
          open={drawerVisible}
        >
          <div className="progress">
            <Progress
              percent={progress}
              showInfo={false}
              strokeColor={progress === 100 ? "green" : "orange"}
            />
            <span>{progress}% Completed</span>
          </div>
          <List
            dataSource={lecturesList}
            renderItem={(item, index) => (
              <List.Item
                className={`lecture-item ${item.completed ? "completed" : ""} ${
                  item.id === selectedLecture?.id ? "active" : ""
                }`}
                onClick={() => handleLectureClick(item)}
              >
                {index + 1}. {item.name}
                {item.completed && (
                  <span className="completed-badge">Completed</span>
                )}
              </List.Item>
            )}
          />
        </Drawer>
        <div className="sidebar">
          <h2>{courseTitle}</h2>
          <p style={{ marginBottom: "0px" }}>{progress}% Completed</p>
          {/* <div className="progress">
            <Progress percent={progress} showInfo={false} />
          </div> */}
          <div className="progress">
            <Progress
              percent={progress}
              showInfo={false}
              strokeColor={progress === 100 ? "green" : "orange"}
              style={{ backgroundColor: "white" }}
            />
          </div>
          <List
            dataSource={lecturesList}
            renderItem={(item, index) => (
              <List.Item
                className={`lecture-item ${
                  item.is_completed ? "completed" : ""
                } ${item.id === selectedLecture?.id ? "active" : ""}`}
                onClick={() => handleLectureClick(item)}
              >
                {index + 1}. {item.name}
                {item.is_completed && (
                  <span className="completed-badge">
                    <FcCheckmark size={18} />
                  </span>
                )}
              </List.Item>
            )}
          />
          {course && course[0]?.name && (
            <>
              <div
                className="testName"
                // onClick={() => navigate(`/quiz/${course && course[0]?.id}`)}
                onClick={handleOpenTest}
              >
                <div className="testName1">{course && course[0]?.name}</div>
              </div>
              <div className="certificate" onClick={handleOpenCertificate}>
                <button
                  className="certificate1"
                  disabled={
                    certificate?.message === "Course is not completed yet." ||
                    certificate?.message ===
                      "You need to complete the test to get the certificate."
                  }
                >
                  Certificate
                </button>
              </div>
            </>
          )}
        </div>

        {/* {(selectedLecture !== "test" || selectedLecture !== "certificate ") && (
          <div className="content">
            <h3>
              Level {currentLevel} of {totalLevels}
            </h3>
            {selectedLecture?.type === "Text" && (
              <>
                <h1>{lectureContent?.name}</h1>
                <ContentComponent htmlContent={lectureContent?.content} />
              </>
            )}
            {selectedLecture?.type === "Quiz" && (
              <div className="test-knowledge">
                <h2>Test Your Knowledge</h2>
                {questions && (
                  <Spin spinning={loading}>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      className="test-form"
                    >
                      {questions?.map((question, index) => (
                        <Form.Group as={Row} className="mb-3" key={question.id}>
                          <Form.Label as="legend" column sm={12}>
                            {question.question}
                          </Form.Label>
                          <Col sm={8} lg={24}>
                            {question.quiz_answers?.map((answer) => (
                              <div className="custom-radio" key={answer.id}>
                                <input
                                  type="radio"
                                  id={`q${index + 1}-${answer.id}`}
                                  name={`q${index + 1}`}
                                  value={answer.id}
                                  onChange={(e) =>
                                    handleChange(e, index, answer.id)
                                  }
                                />
                                <label htmlFor={`q${index + 1}-${answer.id}`}>
                                  {answer.option}
                                </label>
                              </div>
                            ))}
                          </Col>
                        </Form.Group>
                      ))}
                      {error && (
                        <div className="error-message">
                          Please answer all questions before submitting.
                        </div>
                      )}
                      <CustomButton
                        text="Submit"
                        bgColor="white"
                        color="#3D3C6E"
                        border="2px solid #3D3C6E"
                      />
                    </Form>
                  </Spin>
                )}
              </div>
            )}
            {(selectedLecture?.type !== "Quiz" ||
              selectedLecture !== "test") && (
              <div className="btn-container">
                <CustomButton text="Continue" onClick={handleContinue} />
              </div>
            )}
          </div>
        )} */}
        {selectedLecture !== "test" && selectedLecture !== "certificate" && (
          <div className="content">
            <h3>
              Level {currentLevel} of {totalLevels}
            </h3>

            {selectedLecture?.type === "Text" && (
              <>
                <h1>{lectureContent?.name}</h1>
                <ContentComponent htmlContent={lectureContent?.content} />
              </>
            )}

            {selectedLecture?.type === "Quiz" && (
              <div className="test-knowledge">
                <h2>Test Your Knowledge</h2>
                {questions && (
                  <Spin spinning={loading}>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      className="test-form"
                    >
                      {questions?.map((question, index) => (
                        <Form.Group as={Row} className="mb-3" key={question.id}>
                          <Form.Label as="legend" column sm={12}>
                            {question.question}
                          </Form.Label>
                          <Col sm={8} lg={24}>
                            {question.quiz_answers?.map((answer) => (
                              <div className="custom-radio" key={answer.id}>
                                <input
                                  type="radio"
                                  id={`q${index + 1}-${answer.id}`}
                                  name={`q${index + 1}`}
                                  value={answer.id}
                                  onChange={(e) =>
                                    handleChange(e, index, answer.id)
                                  }
                                />
                                <label htmlFor={`q${index + 1}-${answer.id}`}>
                                  {answer.option}
                                </label>
                              </div>
                            ))}
                          </Col>
                        </Form.Group>
                      ))}
                      {error && (
                        <div className="error-message">
                          Please answer all questions before submitting.
                        </div>
                      )}
                      <CustomButton
                        text="Submit"
                        bgColor="white"
                        color="#3D3C6E"
                        border="2px solid #3D3C6E"
                      />
                    </Form>
                  </Spin>
                )}
              </div>
            )}

            {/* Continue Button */}
            {selectedLecture?.type !== "Quiz" && (
              <div className="btn-container">
                <CustomButton text="Continue" onClick={handleContinue} />
              </div>
            )}
          </div>
        )}

        {selectedLecture === "test" && (
          <div className="content">
            <Quiz
              courseid={course[0]?.id}
              SelectedLecturetype={setSelectedLecture}
            />
          </div>
        )}
        {selectedLecture === "certificate" && (
          <div className="content">
            <Certificate data={certificate} />
          </div>
        )}
        {/* {selectedLecture === "test" && <h1>hello</h1>} */}
        {quizResults && (
  <Result
    isVisible={showScoreboard}
    onClose={() => setShowScoreboard(false)}
    quizResults={quizResults}
    restartQuiz={restartQuiz}
    // handleContinue={handleContinue}
  />
)}

      </div>
    </Container>
  );
};
export default CourseOngoing;
