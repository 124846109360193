import React, { useRef, useEffect } from "react";

const ContentComponent = ({ htmlContent }) => {
  const videoRefs = useRef([]); // Store references to all video elements

  const decodeHtmlEntities = (html) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = html;
    return textArea.value;
  };

  const processHtmlContent = (html) => {
    const decodedHtml = decodeHtmlEntities(html);

    // Replace video tags with React-compatible video elements
    return decodedHtml.replace(/<video.*?src="([^"]+)".*?<\/video>/g, (_, videoSrc) => {
      return `
        <div class="video-container">
          <video 
            src="${videoSrc}" 
            width="100%" 
            height="auto" 
            controls 
            class="video-player" 
            style="background-color: #7A7C7F;">
            Your browser does not support the video tag.
          </video>
        </div>`;
    });
  };

  const handleVideoPlay = (currentVideo) => {
    // Pause all videos except the currently playing one
    videoRefs.current.forEach((video) => {
      if (video && video !== currentVideo && !video.paused) {
        video.pause();
      }
    });
  };

  const handleVideoPause = (video) => {
  };

  const setupVideoListeners = () => {
    const videos = document.querySelectorAll(".video-player");

    // Clear previous refs and listeners
    videoRefs.current.forEach((video) => {
      video.removeEventListener("play", video.playHandler);
      video.removeEventListener("pause", video.pauseHandler);
    });

    // Update refs and attach event listeners
    videoRefs.current = Array.from(videos);
    videoRefs.current.forEach((video) => {
      const playHandler = () => handleVideoPlay(video);
      const pauseHandler = () => handleVideoPause(video);

      video.addEventListener("play", playHandler);
      video.addEventListener("pause", pauseHandler);

      // Store references to handlers for cleanup
      video.playHandler = playHandler;
      video.pauseHandler = pauseHandler;
    });
  };

  useEffect(() => {
    setupVideoListeners();

    // Cleanup on component unmount
    return () => {
      videoRefs.current.forEach((video) => {
        video.removeEventListener("play", video.playHandler);
        video.removeEventListener("pause", video.pauseHandler);
      });
      videoRefs.current = [];
    };
  }, [htmlContent]);

  const processedContent = processHtmlContent(htmlContent);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: processedContent,
      }}
    />
  );
};

export default ContentComponent;
