import React from "react";
const CourseManagementTop = () => {
  <div>
    <p>The <strong> Course Management </strong>  section in the LMS Admin Panel provides administrators with a
seamless way to create, manage, and organize courses. You can add chapters and quizzes, reorder
content using drag-and-drop, and update course details such as names, descriptions, and prices.
This guide provides detailed steps with examples to ensure a smooth experience.</p>
  </div>;
};
const CreatingNewCourse = () => (
  <div>
    <ul>
      To create a new course, navigate to the <strong> Course </strong> section
      from the left-side menu. Here, you’ll see a list of existing courses along
      with options to manage them. Click on the <strong> + (Add) </strong>{" "}
      button in the top-right corner to start creating a course. You will need
      to fill in three key fields:
      <li style={{ listStyleType: "disc" }}>
        <strong> Course Name: </strong>
        This should be clear and descriptive. For example, “English Grammar -
        Beginner” works well for a foundational course.
      </li>
      <li style={{ listStyleType: "disc" }}>
        <strong> Price: </strong>
        Enter a price between <strong> 0 and 999 </strong> . For instance, you can price a beginner
        course at “199”.
      </li>
      <li style={{ listStyleType: "disc" }}>
        <strong> Course Description: </strong>
        Add a short and engaging summary to explain the course&#39;s purpose. A
        good example is:
        <br />
        “This is a beginner course for people who want to start learning English
        Grammar.”
      </li>
    </ul>
    <p>
      Once you have filled in all the details, click on{" "}
      <strong> Save &amp; Next </strong> to proceed. The system will create the
      course, and you will be able to add chapters and quizzes next.
    </p>
  </div>
);
const AddingChaptersAndQuizzes = () => (
  <div>
    <p>
      After creating a course, you can organize its content by adding{" "}
      <strong> Chapters </strong> and <strong> Quizzes </strong>.
    </p>
    <ul>
      To add a chapter, click on the <strong> + Chapter </strong> button. A form
      will appear where you need to provide the <strong> Section Name </strong>{" "}
      (e.g., “Nouns”) and the <strong> Section Content </strong> . The content
      editor uses <strong> CKEditor </strong> , a powerful tool that lets you
      add:
      <li style={{ listStyleType: "disc" }}>
        <strong> Text: </strong>
        Write regular text explanations, definitions, or notes. To highlight
        specific content on the frontend, start a sentence or paragraph with
        #summary or #notes. For example:
        <ul>
          <li style={{ listStyleType: "circle" }}>
            #summary Nouns are words that name people, places, or things.
          </li>
          <li style={{ listStyleType: "circle" }}>
            #notes Note: This chapter includes examples for better
            understanding.
          </li>
        </ul>
      </li>
      <li style={{ listStyleType: "disc" }}>
        <strong> Images: </strong>
        You can copy and paste images directly into the editor or upload them
        from your computer. Simply click the <strong> Image </strong> icon (��️)
        in the editor toolbar, select the file, and upload it. For instance,
        upload a diagram showing “Common Nouns vs Proper Nouns”.
      </li>
      <li style={{ listStyleType: "disc" }}>
        <strong> Videos: </strong>
        Videos can be embedded using a code from platforms like{" "}
        <strong> YouTube </strong>
        or <strong> Vimeo </strong> . To do this, first upload your video to
        YouTube/Vimeo and copy the <strong> embed code </strong> . Paste the
        code into the editor, and the video will appear on the frontend. An
        example embed code looks like this:
      </li>
      <li style={{ listStyleType: "disc" }}>
        &lt;iframe src=&quot;https://www.youtube.com/embed/VIDEO_ID&quot;
        allowfullscreen&gt;&lt;/iframe&gt;
      </li>
    </ul>
    <p>
      If you need assistance while adding a chapter, click on the{" "}
      <strong> Read Instructions </strong> link located at the top-right corner
      of the page. A slider will open from the right side of the screen with a
      brief guide to help you through the process. Once the chapter content is
      ready, click <strong> Submit </strong> to save it.
    </p>
    <p>
      To add a quiz, follow similar steps by clicking{" "}
      <strong> + Add Quiz </strong> . Provide the quiz title, questions, and any
      additional content using the editor.
    </p>
  </div>
);
const ReorderingContent = () => (
  <div>
    <p>
      The LMS allows you to rearrange chapters and quizzes using a simple
      <strong> drag-and-drop </strong> feature. Hover over the chapter or quiz
      title you want to move, then click and drag it to its new position. For
      example, if you have the chapters “Nouns”, “Verbs”, and “Adjectives” but
      want “Adjectives” to appear second, simply drag it into place. This makes
      organizing content intuitive and flexible.
    </p>
  </div>
);
const DeletingChapters = () => (
  <div>
    <ul>
      If you need to update or remove a chapter or quiz, click on the{" "}
      <strong> three vertical dots </strong> (⋮) next to the respective content.
      This menu gives you two options:
      <li style={{ listStyleType: "disc" }}>
        <strong> Edit: </strong> Update the title, content, or any other
        details.
      </li>
      <li style={{ listStyleType: "disc" }}>
        <strong> Delete: </strong> Permanently remove the chapter or quiz.
      </li>
    </ul>
    <p>
       ⚠ <strong> Note: </strong> Deleting content is irreversible, so
      double-check before proceeding.
    </p>
  </div>
);
const DeletingCourse = () => (
  <div>
    <ul>
      The course itself can also be updated or deleted. On the course page,
      locate the <strong> three vertical dots </strong> (⋮) in the{" "}
      <strong> top-right banner </strong> . Clicking this menu gives you the
      following options:
      <li style={{ listStyleType: "disc" }}>
        <strong> Edit: </strong>
        Modify the <strong> Course Name </strong> , <strong> Price </strong> ,
        or <strong> Description </strong> . For example, you may want to reduce
        the course price from 199 to 150 or clarify the description.
      </li>
      <li style={{ listStyleType: "disc" }}>
        <strong> Delete: </strong>
        Completely remove the course along with all its content, including
        chapters and quizzes. A confirmation prompt will appear before deletion
        to avoid accidental loss.
      </li>
    </ul>
    <p>
       ⚠ <strong> Warning: </strong> Once deleted, a course cannot be recovered.
    </p>
  </div>
);
const CourseManagement = ({ order }) => {
  const sections = [
    {
      title: "Course Management Guide",
      description:<CourseManagementTop/>,
          subSections: [
        {
          title: "Creating a New Course",
          content: <CreatingNewCourse />,
        },
        {
          title: "Adding Chapters and Quizzes",
          content: <AddingChaptersAndQuizzes />,
        },
        {
          title: "Reordering Chapters and Quizzes",
          content: <ReorderingContent />,
        },
        {
          title: "Editing or Deleting Chapters and Quizzes",
          content: <DeletingChapters />,
        },
        {
          title: "Editing or Deleting a Course",
          content: <DeletingCourse />,
        },
      ],
    },
  ];
  return (
    <ol style={{ listStyle: "none" }} className="font_size_17">
      {sections.map((section, sectionIndex) => (
        <li key={`section-${sectionIndex}`}>
          <h2>
            {Number(sectionIndex) + Number(order)}. {section.title}
          </h2>
          <div>{section.description}</div>
          {section.subSections && (
            <ol style={{ listStyle: "none" }} className="font_size_17">
              {section.subSections.map((subSection, subIndex) => (
                <li key={`subsection-${subIndex}`}>
                  <div className="heading">
                    {Number(sectionIndex) + Number(order)}.{subIndex + 1}{" "}
                    {subSection.title}
                  </div>
                  <div>{subSection.content}</div>
                </li>
              ))}
            </ol>
          )}
        </li>
      ))}
    </ol>
  );
};
export default CourseManagement;
