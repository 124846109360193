import React, { useEffect, useState } from "react";
import { Skeleton } from "antd";
import { Container } from "react-bootstrap";
import VideoCard from "../VideoCard/VideoCard";
import { DashBoardItems } from "../../apis/Api";
import { useNavigate } from "react-router";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import "./Dashboard.scss";

const Dashboard = () => {
  const [courses, setCourses] = useState([]);
  const [tests, setTests] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [loadingTests, setLoadingTests] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = () => {
      const type = "course";
      const formdata = {
        type: type,
      };
      DashBoardItems(formdata)
        .then((res) => {
          if (res.data.success) {
            const fetchedCourses = res.data.data.data
              .filter((item) => item.dashboard_course)
              .map((item) => ({
                image: item.dashboard_course?.course_attachments[0]?.file_name,
                title: item.dashboard_course.name,
                rating: item.dashboard_course.average_rating,
                button_status: "Continue Learning",
                type: type,
                course_id: item.dashboard_course.id,
                progress: item.progress,
                link: `/coursedetails/${item?.course_id}`, // Encode the course ID in the link
                navigateLink: `/courseongoing/${item.dashboard_course.id}`,
                handleClick: handleNavigate,
                dashboard: "dashboard",
              }));
            setCourses(fetchedCourses);
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setLoadingCourses(false);
        });
    };

    fetchCourses();
  }, []);

  const handleNavigate = (id, type, navigateLink) => {
    navigate(navigateLink);
  };

  return (
    <Container>
      <div className="dashboard-course">
        <h3>My Learning</h3>
        {loadingCourses ? (
          <div className="loader">
            <Skeleton active paragraph={{ rows: 4 }} />
          </div>
        ) : courses.length > 0 ? (
          <VideoCard cardData={courses} type="course" />
        ) : (
          <NoDataAvailable message="No courses available" />
        )}
      </div>
    </Container>
  );
};

export default Dashboard;
