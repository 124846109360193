import React from "react";
const CorporateManagementTop = () => {
  <div>
    <p>
      The <strong> Corporate Management</strong> section enables the admin to
      handle companies registered on the platform, view their details, and
      access associated employee information. Corporates can directly sign up on
      the platform, and they are distinguished from regular users by their
      ability to manage employees and their progress.
    </p>
  </div>;
};
const CorporateManagementtable = () => (
  <div>
    <ul style={{ listStyleType: "disc" }}>
      <strong>Corporate Management table: </strong> In the{" "}
      <strong> Corporate Management </strong> table, all corporate accounts are
      listed with details such as the owner&#39;s name, email address, company
      name, the number of employees associated, and account creation date. Each
      corporate entry has <strong> two actions </strong> available:
      <li>
        The <strong> View </strong> button provides a detailed overview of the
        corporate account, including the company’s name, owner details, and
        employee lists.
      </li>
      <li>
        The <strong> Delete </strong> button allows the admin to remove a
        corporate account from the system if necessary.
      </li>
    </ul>
    <ul style={{ listStyleType: "disc" }}>
      When viewing a specific corporate profile, the admin can see:
      <li>
        <strong> Corporate Owner Name </strong>
      </li>
      <li>
        <strong> Email Address</strong>
      </li>
      <li>
        <strong> Company Name </strong>
      </li>
      <li>
        <strong> Mobile Number </strong> (if provided)
      </li>
    </ul>
    <p>Below these details, two types of employees are categorized:</p>
  </div>
);
const CompanyEmployees = () => (
  <div>
    <ul style={{ listStyleType: "disc" }}>
      Company employees are directly employed by the corporate entity. The
      corporate purchases courses for these employees, and the admin can track
      their course progress, certificates earned, and other related data. For
      each employee, clicking on their profile provides detailed information,
      similar to what is displayed for any regular user. This includes:
      <li>
        <strong> Courses Purchased </strong>with progress percentages and prices
      </li>
      <li>
        <strong> Certificates </strong>earned
      </li>
      <li>
        <strong> Test Details,</strong>including scores and attempt history
      </li>
    </ul>
  </div>
);
const ContractEmployees = () => (
  <div>
    <ul style={{ listStyleType: "disc" }}>
      Contract employees are part-time or freelance workers hired by the
      corporate. Unlike company employees, these individuals purchase courses by
      themselves, but the corporate can <strong> request access </strong>  to their data for
      monitoring purposes. The available data includes:
      <li>The number of courses purchased</li>
      <li>Progress on individual courses</li>
      <li>Certificates earned</li>
    </ul>
    <p>
      This differentiation allows corporates to keep track of both their
      permanent and temporary workforce&#39;s progress on the platform while
      maintaining flexibility for contract employees.
    </p>
  </div>
);
const EmployeeView = () => (
  <div>
    <p>
      For both<strong> Company Employees </strong> and{" "}
      <strong> Contract Employees </strong>, the admin can click on individual
      employee names to access detailed user data. This includes course
      progress, test attempts, certificates, and purchase history, identical to
      what is displayed for any other user in the{" "}
      <strong> User Management </strong>
      section.
    </p>
  </div>
);

const CorporateManagement = ({ order }) => {
  const sections = [
    {
      title: "Corporate Management",
      description: <CorporateManagementTop />,
      subSections: [
        {
          title: "Corporate Management table:",
          content: <CorporateManagementtable />,
        },
        {
          title: "Company Employees",
          content: <CompanyEmployees />,
        },
        {
          title: "Contract Employees",
          content: <ContractEmployees />,
        },
        {
          title: "Employee View",
          content: <EmployeeView />,
        },
      ],
    },
  ];
  return (
    <ol style={{ listStyle: "none" }} className="font_size_17">
      {sections.map((section, sectionIndex) => (
        <li key={`section-${sectionIndex}`}>
          <h2>
            {Number(sectionIndex) + Number(order)}. {section.title}
          </h2>
          <div>{section.description}</div>
          {section.subSections && (
            <ol style={{ listStyle: "none" }} className="font_size_17">
              {section.subSections.map((subSection, subIndex) => (
                <li key={`subsection-${subIndex}`}>
                  <div className="heading">
                    {Number(sectionIndex) + Number(order)}.{subIndex + 1}{" "}
                    {subSection.title}
                  </div>
                  <div>{subSection.content}</div>
                </li>
              ))}
            </ol>
          )}
        </li>
      ))}
    </ol>
  );
};
export default CorporateManagement;
