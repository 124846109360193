import { List } from "antd";
import React from "react";

const CourseCurriculm = ({ data }) => {
  return (
    <div className="Course_Curriculm_Section">
      <h5>Course Curriculm</h5>
      <List
        size="small"
        bordered
        dataSource={data}
        renderItem={(item) => <List.Item>{item?.name}</List.Item>}
      />
    </div>
  );
};

export default CourseCurriculm;
