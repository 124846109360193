import React from "react";
const TestManagementGuide1 = () => (
  <div>
    <p>
      The <strong> Test Management </strong> section in the LMS Admin Panel
      gives administrators full control over creating, organizing, and managing
      tests associated with various courses. This section is designed to
      streamline the process of setting up tests, managing questions, and
      ensuring a smooth evaluation experience for students. From adding a new
      test to editing questions or deleting outdated tests, everything can be
      handled efficiently through the Test Management interface.
    </p>
    <p>
      <strong>5.1 </strong>
      When you access the <strong> Test </strong> section from the left-hand
      navigation menu, you will see a comprehensive list of all the tests
      created in the system. Each test entry includes details such as the{" "}
      <strong> Test Name </strong> , the course it is associated with, the{" "}
      <strong> Price </strong> , the <strong> Number of Questions </strong> ,
      and the <strong> Duration </strong> . This overview makes it easy to track
      all tests in one place. You will also find three action buttons next to
      each test: the eye icon (👁️) to view the test details, the edit icon (✏️)
      to modify it, and the delete icon (🗑️) to remove the test. These options
      allow you to manage each test with ease.
    </p>
  </div>
);

const TestManagement = ({ order }) => {
  const sections = [
    {
      title: "Test Management Guide",
      description: <TestManagementGuide1 />,
    },
  ];
  return (
    <ol style={{ listStyle: "none" }} className="font_size_17">
      {sections.map((section, sectionIndex) => (
        <li key={`section-${sectionIndex}`}>
          <h2>
            {Number(sectionIndex) + Number(order)}. {section.title}
          </h2>
          <div>{section.description}</div>
          {section.subSections && (
            <ol style={{ listStyle: "none" }} className="font_size_17">
              {section.subSections.map((subSection, subIndex) => (
                <li key={`subsection-${subIndex}`}>
                  <div className="heading">
                    {Number(sectionIndex) + Number(order)}.{subIndex + 1}{" "}
                    {subSection.title}
                  </div>
                  <div>{subSection.content}</div>
                </li>
              ))}
            </ol>
          )}
        </li>
      ))}
    </ol>
  );
};
export default TestManagement;
