import React, { useEffect, useRef, useState } from "react";
import "./CourseDetailsNew.scss";
import { Container } from "react-bootstrap";
import { Button, Card, message, Tabs } from "antd";
import CourseAbout from "./CourseAbout";
import CourseCurriculm from "./CourseCurriculm";
import CourseReview from "./CourseReview";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  GetCourseById,
  GetWithTokenCourseById,
  PostAddToCart,
} from "../../apis/Api";
import { getLocalStorageItem } from "../../apis/locastorageHandle";
import { getReviewAndRatings } from "../../redux/reviweAndRatinSlice";
const { TabPane } = Tabs;
const CourseDetailsNew = () => {
  const [loadingCourse, setLoadingCourse] = useState(true);
  const [courseDetails, setCourseDetails] = useState(null);

  const aboutRef = useRef(null);
  const curriculmRef = useRef(null);
  const reviewRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleTabChange = (key) => {
    if (key === "1") aboutRef.current.scrollIntoView({ behavior: "smooth" });
    else if (key === "2")
      curriculmRef.current.scrollIntoView({ behavior: "smooth" });
    else if (key === "3")
      reviewRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const userType = getLocalStorageItem("myValue");
  const token = getLocalStorageItem("userToken");

  const handleAddToCart = () => {
    const formData = { course_id: id };
    PostAddToCart(formData)
      .then((res) => {
        if (res?.data?.success) {
          message.success(
            res?.data?.message || "Course added to cart successfully!"
          );
          setTimeout(() => {
            navigate("/shopingcart");
          }, 2000);
        } else {
          message.error(res?.data?.message || "Failed to add course to cart.");
        }
      })
      .catch((error) => {
        console.error("Error adding to cart:", error);
      });
  };
  useEffect(() => {
    setLoadingCourse(true);
    const fetchCourseDetails = async () => {
      try {
        const response = token
          ? await GetWithTokenCourseById(id)
          : await GetCourseById(id);
        if (response?.data?.success) {
          setCourseDetails(response?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching course details:", error);
      } finally {
        setLoadingCourse(false);
      }
    };
    fetchCourseDetails();
  }, [id, token]);
  return (
    <div className="CourseDetailsNew">
      <div className="CourseDetailsNew_Banner">
        <h1>{courseDetails?.name}</h1>
      </div>
      <Container>
        <div className="Course_card_main">
          <div className="Course_card_left">
            <Tabs defaultActiveKey="1" onChange={handleTabChange}>
              <TabPane tab="About Us" key="1"></TabPane>
              <TabPane tab="Course Curriculm" key="2"></TabPane>
              <TabPane tab="Reviews" key="3"></TabPane>
            </Tabs>
            <div ref={aboutRef}>
              <CourseAbout data={courseDetails?.description} />
            </div>
            <div ref={curriculmRef}>
              <CourseCurriculm data={courseDetails?.course_lectures} />
            </div>
            <div ref={reviewRef}>
              <CourseReview />
            </div>
          </div>
          <div className="Course_card_right">
            <Card className="review_card">
              <ul>
                <strong>This Course Includes :</strong>
                <li>{courseDetails?.course_lectures?.length} lectures</li>
                <li>Certificate of completion</li>
              </ul>
              <hr />
              <h6>Price : </h6>
              <h5>${courseDetails?.price}</h5>
              {userType !== "6" && courseDetails?.is_purchased !== 0 && (
                <Button
                  className="enroll_now_btn"
                  onClick={() =>
                    navigate(`/courseongoing/${courseDetails?.id}`)
                  }
                >
                  Continue Learning
                  <IoIosArrowRoundForward size={25} />
                </Button>
              )}
              {userType === "6" && (
                <>
                  {courseDetails?.is_purchased === 1 ? (
                    <Button
                      className="enroll_now_btn"
                      onClick={() =>
                        navigate(`/courseongoing/${courseDetails?.id}`)
                      }
                    >
                      Continue Learning
                      <IoIosArrowRoundForward size={25} />
                    </Button>
                  ) : courseDetails?.is_in_cart === 1 ? (
                    <Button
                      className="enroll_now_btn"
                      onClick={() => navigate("/shopingcart")}
                    >
                      Go To Cart
                      <IoIosArrowRoundForward size={25} />
                    </Button>
                  ) : (
                    <Button
                      className="enroll_now_btn"
                      onClick={handleAddToCart}
                    >
                      Add To Cart <IoIosArrowRoundForward size={25} />
                    </Button>
                  )}
                </>
              )}
            </Card>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default CourseDetailsNew;
