import React from "react";

const StaticContentManagementTop = () => (
  <div>
    <p>
      The Static Content Management section allows the admin to oversee and
      manage user-generated queries and customer support requests efficiently.
      This includes managing <strong>Forums</strong> where user questions are
      answered and the <strong>Contact Us Form</strong> where customer support
      inquiries are recorded.
    </p>
  </div>
);

const ForumManagement = () => (
  <div>
    <p>
      The <strong>Forum Management </strong> section is designed to manage
      user-submitted queries. Here, users post questions that they need help
      with, and the admin can select these questions and provide appropriate
      answers. Importantly, the admin cannot post new questions; they can only
      respond to existing ones.
    </p>
    <p>
      The table displays a list of questions submitted by users, alongside the
      name of the user, date of submission, and the status of the response.
      Questions that have not been answered by the admin will show the message{" "}
      <strong> &quot;Answer is not provided by admin yet&quot; </strong>in red
      text, indicating pending action. Once answered, the admin&#39;s response
      is visible in the corresponding &quot;Answer&quot; column.
    </p>
    <p>
      To respond to a question, the admin can click the{" "}
      <strong> edit (pencil icon) </strong> under the Action column. A new screen
      will open with a <strong> Question </strong> field (showing the user&#39;s
      submitted question) and an <strong> Answer </strong>  field where the admin can type the
      response. After entering the answer, clicking the <strong> Save </strong>  button will update
      the forum with the admin&#39;s response. For example, if a user posts a
      question like{" "}
      <em>
        &quot;How can I get a discount?&quot;, the admin can respond with:
        &quot;You can use the promo code SAVE20 during checkout to avail a 20%
        discount on all courses.&quot;
      </em>{" "}
    </p>
    <p>
      In case the admin needs to delete a question, they can click the{" "}
      <strong> delete (trash icon) </strong> in the Action column, and the
      question will be removed. This streamlined approach ensures all user
      concerns are addressed promptly while maintaining the integrity of the
      forum.
    </p>
  </div>
);
const ContactUsForm = () => (
  <div>
    <p>
      The <strong>Contact Us Form</strong> section records all user-submitted
      support queries. This includes essential details such as the user&#39;s{" "}
      <strong>Name, Email, Contact Number, Enquiry Details, Help Topic</strong>{" "}
      , and the <strong> Date </strong> of submission. This form serves as a
      central repository for all customer support inquiries.
    </p>
    <p>
      The admin can view the submitted queries and use the search bar to filter
      results based on specific user details or keywords. For instance, if a
      user named John Doe submits a query asking for support with accessing a
      course, the admin can quickly locate and address his concern. If required,
      any irrelevant or inappropriate query can be deleted by clicking on the{" "}
      <strong>delete (trash icon)</strong> under the <strong> Action </strong>  column.
    </p>
    <ul style={{ listStyleType: "disc" }}>
      Here’s an example of how the data might look:
      <li>
        <strong> Name </strong>: John Doe
      </li>
      <li>
        <strong> Email </strong>: john.doe@domain.com
      </li>
      <li>
        <strong> Contact Number </strong>: 123456789
      </li>
      <li>
        <strong> Enquiry Details </strong>: &quot;I am unable to access the course
        materials.&quot;
      </li>
      <li>
        <strong> Help Topic </strong>: Access Issuee
      </li>
      <li>
        <strong> Date </strong>: 12-16-2024
      </li>
    </ul>
    <p>
      This system allows the admin to organize, monitor, and respond to customer
      support inquiries efficiently, ensuring that no query goes unresolved.
    </p>
  </div>
);

const ContentManagement = ({ order }) => {
  const sections = [
    {
      title: "Static Content Management",
      description: <StaticContentManagementTop />,
      subSections: [
        {
          title: "Forum Management",
          content: <ForumManagement />,
        },
        {
          title: "Contact Us Form",
          content: <ContactUsForm />,
        },
      ],
    },
  ];
  return (
    <ol style={{ listStyle: "none" }} className="font_size_17">
      {sections.map((section, sectionIndex) => (
        <li key={`section-${sectionIndex}`}>
          <h2>
            {Number(sectionIndex) + Number(order)}. {section.title}
          </h2>
          <div>{section.description}</div>
          {section.subSections && (
            <ol style={{ listStyle: "none" }} className="font_size_17">
              {section.subSections.map((subSection, subIndex) => (
                <li key={`subsection-${subIndex}`}>
                  <div className="heading">
                    {Number(sectionIndex) + Number(order)}.{subIndex + 1}{" "}
                    {subSection.title}
                  </div>
                  <div>{subSection.content}</div>
                </li>
              ))}
            </ol>
          )}
        </li>
      ))}
    </ol>
  );
};
export default ContentManagement;
