import React, { useEffect, useState } from "react";
import { Tabs, Layout, Dropdown, Menu, Modal, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Container } from "react-bootstrap";
import ProfileForm from "../ProfileForm/ProfileForm";
import PurchaseHistory from "../PurchaseHistory/PurchaseHistory";
import Invoices from "../Invoices/Invoices";
import { GetCompanyName, PostShareData } from "../../apis/Api";
import "./Profile.scss";
import SharedData from "../SharedData/SharedData";
const { TabPane } = Tabs;
const { Content } = Layout;
const Profile = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleMenuClick = (e) => {
    const selected = companies.find(
      (company) => company.id === parseInt(e.key)
    );
    setSelectedCompany(selected);
    setDropdownVisible(false);
    setIsModalVisible(true);
  };
  const handleOk = () => {
    if (selectedCompany) {
      PostShareData(selectedCompany.id)
        .then((res) => {
          message.success(`Data shared with ${selectedCompany.company_name}`);
        })
        .catch((error) => {
          console.log("error", error);
          message.error("Failed to share data");
        });
      setIsModalVisible(false);
    } else {
      message.error("No company selected");
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    GetCompanyName()
      .then((res) => {
        if (res.data && res.data.data) {
          setCompanies(res.data.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);
  return (
    <Container>
      <Layout className="profile-container">
        <Content className="profile-content">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Profile" key="1">
              <ProfileForm />
            </TabPane>
            <TabPane tab="Purchase History" key="3">
              <PurchaseHistory />
            </TabPane>
            <TabPane tab="Shared Data" key="4">
              <SharedData />
            </TabPane>
          </Tabs>
        </Content>
      </Layout>
    </Container>
  );
};
export default Profile;
