import React from "react";
const DashboardOverview = () => (
  <div>
    <p  >
      The <strong> Dashboard </strong> is the central hub of the LMS Admin
      Panel, providing administrators with a quick and comprehensive snapshot of
      the platform’s performance. It consolidates key statistics and insights
      into a single, easy-to-navigate screen, allowing you to monitor user
      activity, course performance, revenue generation, and more. The dashboard
      is organized into various sections, each presenting valuable data with
      clear visuals such as graphs, tables, and charts.
    </p>
  </div>
);
const WelcomeSectionAndKeyMetrics = () => (
  <div>
    <p  >
      At the top of the Dashboard, administrators are greeted with a message,
      <em>“Welcome Admin”,</em>, followed by an overview of the latest platform
      statistics. Here, you can see summarized key metrics, including the{" "}
      <strong> Total Users Registered, Total Corporate Accounts, </strong> and{" "}
      <strong> Total Courses Available. </strong>
      These figures provide an immediate understanding of platform activity. For
      example, you might see that <strong> 9 users </strong> are registered,
      <strong> 2 corporate accounts </strong>
      have been created, and there are <strong>
        {" "}
        10 courses available{" "}
      </strong>{" "}
      on the system.
    </p>
    <p  >
      Below these key metrics, additional insights such as the{" "}
      <strong>
        Number of Users Who Purchased a Course, Number of Forums Submitted,
      </strong> and <strong> Number of Contact Us Forms Received </strong> are displayed.
      This section enables admins to track user engagement and communication
      patterns efficiently.
    </p>
  </div>
);
const RevenueAndCourseSales = () => (
  <div>
    <p  >
      The dashboard also provides a clear breakdown of financial performance. At
      the top-left, a<strong> Total Revenue Generated </strong> card displays
      the platform’s revenue for a specified time period. By default, the data
      is shown <strong> Weekly </strong>, but you can switch between{" "}
      <strong> Today, This Week, </strong> and <strong> This Month </strong>{" "}
      using the dropdown. Adjacent to this, the Courses Sold metric shows the
      number of courses purchased by users.
    </p>
    <p  >
      For example, if the dashboard indicates that <strong> $1828 </strong> has
      been generated this week and <strong> 3 courses </strong> have been sold,
      administrators can quickly gauge how well their courses are performing in
      terms of sales and revenue.
    </p>
  </div>
);
const UserAndCorporateRegistrationAnalytics = () => (
  <div>
    <p  >
      The dashboard includes two dedicated graphs for tracking registrations. On
      the left, a <strong> User’s Registered </strong> graph displays the number
      of individual users who signed up during a specific period. On the right,
      a <strong> Corporates Registered </strong> graph highlights new corporate
      accounts. Both graphs allow you to toggle between Today, This Week, and
      This Month views for more granular insights.
    </p>
    <p  >
      For instance, the graph might show that <strong> 50 users </strong>{" "}
      registered this week, while corporate registrations remained at
      <strong> 10 </strong> . Such data helps administrators identify growth
      patterns and focus on increasing user acquisition.
    </p>
  </div>
);
const TopPerformingCourses = () => (
  <div>
    <p  >
      To help admins analyze course performance, the dashboard highlights the
      <strong> Top Selling Courses </strong> and their respective statistics.
      Each entry includes the <strong> Course Image, Course Name, </strong> and
      the <strong> Total Purchases. </strong> For instance, the table may show
      that the <em>“Data Structures and Algorithms”</em> course has been
      purchased <strong> 4 times </strong>, tying it with{" "}
      <em>“Test Course” </em>and <em>“Sample Course”</em>.
    </p>
    <p  >
      On the right side, the <strong> Course Completion Rate </strong> is
      presented as a pie chart. This chart divides users into two categories:
      those who have <strong> Completed </strong>
      their courses and those who have <strong> Not Completed </strong> them. A
      balanced or high completion rate indicates course effectiveness, while a
      lower rate may highlight areas for improvement.
    </p>
  </div>
);
const CourseRatingsAndPerformance = () => (
  <div>
    <p  >
      The dashboard also includes sections for{" "}
      <strong> Top Highest Rated Courses </strong> and the
      <strong> Average Rating of Courses </strong>. These tables display the{" "}
      <strong> Course Name </strong>alongside its{" "}
      <strong> Average Rating </strong>, represented visually with star icons.
      For example,
      <em>“Test Course”</em> might have an average rating of{" "}
      <strong> 4 stars </strong>, while “Data Structures and Algorithms” holds a
     <strong> 3.5-star rating. </strong>  
    </p>
    <p  >
      This information allows admins to quickly identify the most successful
      courses based on learner feedback and ratings. Courses with consistently
      lower ratings can be reviewed for improvements in content quality or
      delivery.
    </p>
  </div>
);
const SummaryOfInsights = () => (
  <div>
    <p  >
      The LMS Dashboard provides all the necessary tools and visualizations to
      track platform performance. From user activity to course performance,
      administrators can monitor every aspect of the system in one place. Key
      metrics like <strong> revenue generated, course sales </strong>,and{" "}
      <strong> user registrations </strong> help gauge platform growth, while
      insights such as <strong> course completion rates </strong> and
      <strong> average ratings </strong> ensure course quality remains a
      priority. The easy-to-read visuals, including pie charts, graphs, and
      tables, make it simple to identify trends and take necessary actions to
      optimize the platform.
    </p>
  </div>
);
const DashboardManagement = ({ order }) => {
  const sections = [
    {
      title: "Dashboard Overview",
      description: <DashboardOverview />,
      subSections: [
        {
          title: "Welcome Section and Key Metrics",
          content: <WelcomeSectionAndKeyMetrics />,
        },
        {
          title: "Revenue and Course Sales",
          content: <RevenueAndCourseSales />,
        },
        {
          title: "User and Corporate Registration Analytics",
          content: <UserAndCorporateRegistrationAnalytics />,
        },
        {
          title: "Top Performing Courses",
          content: <TopPerformingCourses />,
        },
        {
          title: "Course Ratings and Performance",
          content: <CourseRatingsAndPerformance />,
        },
        {
          title: "Summary of Insights",
          content: <SummaryOfInsights />,
        },
      ],
    },
  ];
  return (
    <ol style={{ listStyle: "none" }} className="font_size_17">
      {sections.map((section, sectionIndex) => (
        <li key={`section-${sectionIndex}`}>
          <h2>
            {Number(sectionIndex) + Number(order)}. {section.title}
          </h2>
          <div>{section.description}</div>
          {section.subSections && (
             <ol style={{ listStyle: "none" }} className="font_size_17">
              {section.subSections.map((subSection, subIndex) => (
                <li key={`subsection-${subIndex}`}>
                  <div className="heading">

                  
                    {Number(sectionIndex) + Number(order)}.{subIndex + 1}{" "}
                    {subSection.title}
               
                  </div>
                  <div>{subSection.content}</div>
                </li>
              ))}
            </ol>
          )}
        </li>
      ))}
    </ol>
  );
};
export default DashboardManagement;
