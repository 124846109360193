import React from "react";
const AdminManagementTop = () => {
  <div>
    <p>
      The Admin Management module allows the primary system owner to create,
      edit, delete, and manage administrators within the platform. This ensures
      a well-structured hierarchy, where specific roles and responsibilities can
      be assigned to different admins.
    </p>
  </div>;
};
const AdminListOverview = () => (
  <div>
    <ul style={{ listStyleType: "disc" }}>
      The Admin List displays all existing administrators, including their
      names, email addresses, and available actions for managing them. For each
      admin listed:
      <li>
        The <strong> edit </strong> button enables modification of their
        information or role permissions.
      </li>
      <li>
        The <strong> delete </strong> button allows the removal of an admin
        account from the system.
      </li>
    </ul>
    <p>
      Admins are categorized based on their assigned permissions, ensuring they
      only access relevant sections of the platform. The “Add New Admin” button
      on the top-right enables the creation of new admin accounts.
    </p>
  </div>
);
const AddingaNewAdmin = () => (
  <div>
    <ol>
      To add a new admin, the owner needs to click on the{" "}
      <strong> “Add New Admin” </strong>
      button, which opens a form requiring the following details:
      <li>
        <strong> Name </strong>: Full name of the new admin.
      </li>
      <li>
        <strong> Email </strong> : A valid email address where login credentials
        will be sent.
      </li>
      <li>
        <strong> Roles </strong>: Select the appropriate roles and permissions
        that define what sections the admin can manage.
      </li>
    </ol>
    <h6>Role Assignment</h6>
    <ul style={{ listStyleType: "disc" }}>
      The roles are modular, allowing flexibility in what access the admin
      receives. Options include:
      <li>
        <strong> Access Management </strong> : Allows oversight of admins and
        roles.
      </li>
      <li>
        <strong> Payment Management </strong>: Grants access to payment data and
        transactions.
      </li>
      <li>
        <strong> User Management </strong> :
        <ul style={{ listStyleType: "circle" }}>
          <li>
            <strong> Individual User Management </strong>: Focuses on managing
            individual user accounts.
          </li>
          <li>
            <strong>Corporate User Management</strong>: Focuses on managing
            corporate user accounts.
          </li>
        </ul>
        <li>
          <strong>Course Management:</strong>
          <ul style={{ listStyleType: "circle" }}>
            <li><strong>Course</strong>: Grants access to course-related modules.</li>
            <li><strong>Test</strong>: Allows management of tests and assessments.</li>
          </ul>
        </li>
        <li>
          <strong>Content Management:</strong>
          <ul style={{ listStyleType: "circle" }}>
          <li>
            <strong> Forum Management </strong>: Enables the admin to answer
            forum queries.
          </li>
          <li>
            <strong> Contact Us Forms </strong>: Allows viewing and managing
            support queries submitted via the Contact Us section.
          </li>
          </ul>
        </li>
       
        <li>
          <strong>Dashboard Management</strong>: Provides visibility into
          overall platform analytics and key metrics.
        </li>
      </li>
    </ul>
    <p>
      The roles selected define the admin’s scope of access. For example, an
      admin assigned to <strong> User Management </strong> will not have access
      to Payment or Content sections unless explicitly granted permission.
    </p>
  </div>
);
const AdminAccountCreationandAccess = () => (
  <div>
    <p>
      Once the new admin details are submitted, the system generates a temporary
      password and sends it, along with login credentials, to the admin&#39;s
      provided email. The admin can use this password to log in initially and
      will be prompted to update it via their dashboard for security purposes.
    </p>
    <h6>Example</h6>
    <p>
      <strong> Scenario </strong>:A payment manager is added to the system.

    </p>
    <ul style={{ listStyleType: "disc" }}>
      <li>
        <strong> Name </strong>: John Doe
      </li>
      <li>
        <strong> Email </strong>: johndoe@company.com
      </li>
      <li>
        <strong>Roles</strong>: Payment Management, Forum Management, and
        Contact Us Forms.
      </li>
    </ul>
    <p>
      John Doe will only have access to the{" "}
      <strong> Payment Management </strong> module to view payment history and
      receipts, along with <strong> Forum Management </strong>
      to answer queries and <strong> Contact Us Forms </strong> for customer
      support management. All other sections such as User Management and Course
      Management remain inaccessible to him.
    </p>
  </div>
);
const ManagingAdmins = () => (
  <div>
    <p>

      The system owner has full control over all admins:
    </p>
    <ul style={{ listStyleType: "disc" }}>
      <li>
        <strong>Editing Access</strong>: Admin permissions can be updated at any
        time to grant or revoke access to certain modules.
      </li>
      <li>
        <strong>Deleting Admins</strong>: If an admin is no longer required,
        they can be removed from the system.
      </li>
      <li>
        <strong>Limited Access</strong>: Admins will only see and interact with
        sections of the platform assigned to them. All other modules will remain
        inaccessible.
      </li>
    </ul>
    <p>
      This granular control ensures that sensitive data and critical operations
      are only handled by authorized personnel.
    </p>
  </div>
);
const AdminManagement = ({ order }) => {
  const sections = [
    {
      title: "Admin Management",
      description: <AdminManagementTop />,
      subSections: [
        {
          title: "Admin List Overview",
          content: <AdminListOverview />,
        },
        {
          title: "Adding a New Admin",
          content: <AddingaNewAdmin />,
        },
        {
          title: "Admin Account Creation and Access",
          content: <AdminAccountCreationandAccess />,
        },
        {
          title: "Managing Admins",
          content: <ManagingAdmins />,
        },
      ],
    },
  ];
  return (
    <ol style={{ listStyle: "none" }} className="font_size_17">
      {sections.map((section, sectionIndex) => (
        <li key={`section-${sectionIndex}`}>
          <h2>
            {Number(sectionIndex) + Number(order)}. {section.title}
          </h2>
          <div>{section.description}</div>
          {section.subSections && (
            <ol style={{ listStyle: "none" }} className="font_size_17">
              {section.subSections.map((subSection, subIndex) => (
                <li key={`subsection-${subIndex}`}>
                  <div className="heading">
                    {Number(sectionIndex) + Number(order)}.{subIndex + 1}{" "}
                    {subSection.title}
                  </div>
                  <div>{subSection.content}</div>
                </li>
              ))}
            </ol>
          )}
        </li>
      ))}
    </ol>
  );
};
export default AdminManagement;
